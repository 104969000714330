<!-- Entry icon -->
@if (hierarchyNode().type !== 'customer') {
  <div class="icon" (click)="expand($event)">
    @if (hasChild()) {
      <div class="group-expand">
        <svg-icon key="chevron-down"></svg-icon>
      </div>
    }
    <lib-hierarchy-node-icon
      [hierarchyNode]="hierarchyNode()">
    </lib-hierarchy-node-icon>
  </div>
}

<!-- Entry name -->
<a [routerLink]="[]" [queryParams]="queryParams()">
  {{ hierarchyNode().name }}
</a>

<!-- Tool buttons -->
@if (hierarchyNode().type !== 'customer') {
  @if (showConfigMenu() && visibleMenuItems().length) {
    <div class="add" (click)="menu.toggle($event); $event.stopPropagation()">
      <svg-icon key="plus"></svg-icon>
    </div>
  }

  <lib-hierarchy-favorite-icon
    class="favorite"
    [ngClass]="{ selected: isFavorite() }"
    [isFavorite]="isFavorite()"
    (toggleFavorite)="toggleFavorite.emit(hierarchyNode())">
  </lib-hierarchy-favorite-icon>
}

<p-menu #menu [model]="visibleMenuItems()" [popup]="true" appendTo="body">
  <ng-template pTemplate="item" let-item>
    <a class="p-menu-item-link flex items-center" [routerLink]="[]" [queryParams]="item.queryParams">
      <svg-icon [key]="item.icon"></svg-icon>
      <span class="ml-2">{{ item.label }}</span>
    </a>
  </ng-template>
</p-menu>
